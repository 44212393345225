<template>
  <div>
    <feather-icon
      icon="Edit3Icon"
      svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
      data-test="editPlantBtn"
      @click="editRecord"
    />
    <feather-icon
      icon="TrashIcon"
      svgClasses="h-5 w-5 mr-4 hover:text-danger cursor-pointer"
      data-test="deletePlantBtn"
      @click="confirmDeletePlant"
    />
  </div>
</template>

<script>
import { DELETE_PLANT } from '@/graphql/mutations';
import { PLANTS_PAGINATED } from '@/graphql/queries';
export default {
  name: 'CellRendererActions',
  methods: {
    editRecord() {
      this.$gates.hasPermission('edit_plants') &&
        this.$router.push(`/plantas/${this.params.data.id}/actualizar`);
    },
    confirmDeletePlant() {
      this.$gates.hasPermission('edit_plants') &&
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: `Confirmar eliminación`,
          text: `¿Seguro que quieres eliminar la planta ${this.params.data.name} que pertenece a la franquicia ${this.params.data.franchise.name}?`,
          accept: this.deletePlant,
          acceptText: 'Eliminar',
          cancelText: 'Cancelar',
        });
    },
    async deletePlant() {
      try {
        await this.$apollo.provider.defaultClient.mutate({
          mutation: DELETE_PLANT,
          variables: {
            id: this.params.data.id,
          },
          update: (cache) => {
            const data = cache.readQuery({
              query: PLANTS_PAGINATED,
              variables: {
                first: 15,
                page: this.params.context.componentParent.currentPage,
              },
            });
            console.log(data);
            const removeIndex = data.plantsPaginated.data
              .map((plant) => plant.id)
              .indexOf(this.params.data.id);
            data.plantsPaginated.data.splice(removeIndex, 1);
            console.log(data);
            cache.writeQuery({
              query: PLANTS_PAGINATED,
              data,
              variables: {
                first: 15,
                page: this.params.context.componentParent.currentPage,
              },
            });
          },
        });
        this.$vs.notify({
          color: 'success',
          title: `Planta eliminada`,
          text: `Se ha eliminado la planta sactifactoriamente.`,
          position: 'bottom-right',
        });
      } catch (error) {
        this.$vs.notify({
          color: 'danger',
          title: `No se pudo eliminar la planta.`,
          text: `La planta podría tener varias delegaciones asociadas.`,
          position: 'bottom-right',
        });
      }
    },
  },
};
</script>
