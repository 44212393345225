<template>
  <div>
    <TablaPlantas></TablaPlantas>
  </div>
</template>

<script>
import TablaPlantas from '@/components/plantas/TablaPlantas.vue';
export default {
  components: {
    TablaPlantas,
    //
  },
};
</script>

<style></style>
