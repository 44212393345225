<template>
  <vx-card class="tabla-plantas">
    <!-- TABLE ACTION ROW -->
    <div class="flex flex-wrap justify-between items-center">
      <div class="vx-col w-full md:w-1/2 mb-5 md:px-3">
        <vs-select
          class="w-full"
          label="Franquicias"
          v-model="filterFranchiseId"
          autocomplete
          placeholder="Seleccione una franquicia"
        >
          <vs-select-item value="" text="Todas" />

          <vs-select-item
            :key="index"
            :text="item.name"
            :value="item.id"
            v-for="(item, index) in franchises"
          />
        </vs-select>
      </div>

      <div class="flex items-center w-full md:w-1/2">
        <vs-input
          class="flex-1"
          v-model="searchQuery"
          placeholder="Buscar..."
        />
        <div class="px-3">
          <vs-button data-test="addPlantBtn" @click="goToAddPlant"
            >Añadir</vs-button
          >
        </div>
      </div>
    </div>
    <ag-grid-vue
      ref="agGridTable"
      :gridOptions="gridOptions"
      class="ag-theme-material w-100 my-4 ag-grid-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="plants"
      rowSelection="multiple"
      colResizeDefault="shift"
      :animateRows="true"
      :floatingFilter="false"
      :pagination="true"
      :paginationPageSize="paginationPageSize"
      :suppressPaginationPanel="true"
      :enableRtl="$vs.rtl"
      :loading="true"
    >
    </ag-grid-vue>
    <vs-pagination
      :total="totalPages"
      :max="maxPageNumbers"
      v-model="currentPage"
    />
  </vx-card>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import CellRendererActions from './cell-renderer/CellRendererActions';
import { PLANTS_PAGINATED, FRANCHISES } from '@/graphql/queries';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { useQuery, useResult } from '@vue/apollo-composable';

export default defineComponent({
  setup() {
    const currentPage = ref(1);
    const first = ref(15);
    const filterFranchiseId = ref();
    const searchQuery = ref('');
    const { result: franchisesResult } = useQuery(FRANCHISES);
    const franchises = useResult(
      franchisesResult,
      [],
      (data) => data.franchises
    );
    const { result: plantsResult } = useQuery(PLANTS_PAGINATED, {
      page: currentPage,
      first: first,
      franchise_id: filterFranchiseId,
      search: searchQuery,
    });
    const plants = useResult(
      plantsResult,
      [],
      (data) => data.plantsPaginated.data
    );
    const paginatorInfo = useResult(plantsResult, [], (data) => ({
      total: data.plantsPaginated.paginatorInfo.total,
      perPage: data.plantsPaginated.paginatorInfo.perPage,
    }));

    const totalPages = computed(() =>
      Math.ceil(paginatorInfo.value.total / paginatorInfo.value.perPage)
    );
    return {
      totalPages,
      paginatorInfo,
      plants,
      currentPage,
      franchises,
      filterFranchiseId,
      searchQuery,
    };
  },
  components: {
    AgGridVue,
    CellRendererActions,
  },
  data() {
    return {
      negociacionSeleccionada: null,
      //VSPromptStatus
      activePromptStatus: false,
      status: null,
      observacion: '',
      estados: [],
      // Table
      // searchQuery: '',
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: 'Ref',
          field: 'id',
          width: 175,
          filter: true,
        },
        {
          headerName: 'Franquicia',
          field: 'franchise.name',
          width: 175,
          filter: true,
        },
        {
          headerName: 'Nombre',
          field: 'name',
          filter: true,
          width: 250,
        },
        {
          headerName: 'Franquicia',
          field: 'franchise.name',
          filter: true,
          width: 250,
        },
        {
          headerName: 'Descripción',
          field: 'description',
          filter: true,
          width: 120,
        },
        {
          headerName: 'Hora de apertura',
          field: 'start_hour',
          filter: true,
          width: 170,
        },
        {
          headerName: 'Hora de cierre',
          field: 'end_hour',
          filter: true,
          width: 170,
        },
        {
          headerName: 'Acciones',
          field: 'acciones',
          width: 150,
          cellRendererFramework: 'CellRendererActions',
        },
      ],
      allplantas: [
        {
          franchise: { name: '' },
          name: '',
          description: '',
          start_hour: '',
          end_hour: '',
          cupos: 557,
        },
      ],
    };
  },
  watch: {
    '$store.state.windowWidth'(val) {
      if (val <= 576) {
        this.maxPageNumbers = 5;
        // this.gridOptions.columnApi.setColumnPinned('email', null);
      }
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 50;
    },
  },
  methods: {
    goToAddPlant() {
      this.$router.push({ name: 'add-plant' });
    },
    changeStatus({ negociacion, observacion, status }) {
      this.status = status;
      this.negociacionSeleccionada = negociacion;
      this.observacion = observacion;
      this.activePromptStatus = true;
    },
    guardarStatus() {
      // Reactividad despues de haber hecho los cambios exitosamente en el servidor
      this.negociacionSeleccionada.status = this.status;
      this.gridApi.refreshCells();
      this.$vs.notify({
        color: 'success',
        title: 'Estado actualizado!',
        text: 'El estado de la planta fue correctamente actualizado.',
      });
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridOptions.context = {
      componentParent: this,
    };
    if (this.$store.state.windowWidth > 768) {
      this.gridApi.sizeColumnsToFit();
    }
  },
});
</script>

<style lang="scss">
@import '@/assets/scss/vuexy/_variables.scss';

.tabla-plantas {
  &__prompt {
    .dialog-title {
      color: $nepal !important;
      font-weight: $font-weight-bold;
    }
  }
}
</style>
